import styled from 'styled-components'

const TagList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    display: inline-block;
    margin-right: 10px;
    font-size: 0.85rem;

    a {
      color: #ec5658;
    }
  }
`

export default TagList
