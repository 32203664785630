import styled from 'styled-components'

const Content = styled.section`
  line-height: 1.5;
  font-size: calc(16px + 0.15vw);

  h1 {
    margin-bottom: ${props => `${props.marginBottom}px` || '0px'};
  }

  h2 {
    background-color: inherit;
  }

  a {
    color: ${props => props.theme.linkColor};
    text-decoration: none;
    padding: 0 1px;
  }

  .gatsby-resp-image-link {
    background-color: inherit;
    border-bottom: none;
  }

  .gatsby-resp-image-link:hover {
    background-color: inherit;
    border-bottom: none;
  }

  code {
    font-size: 16px;
  }
`

export default Content
