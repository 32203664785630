import React from 'react'
import { Link } from 'gatsby'
import TagList from '../atoms/TagList'

export default function PostTags({ tags, collection }) {
  if (!tags) return null

  return (
    <TagList>
      {tags.map(tag => {
        let tagUrl = `tags/${tag}`
        if (collection !== 'blog') {
          tagUrl = `${collection}/tags/${tag}`
        }

        return (
          <li key={tag}>
            <Link to={tagUrl}>#{tag}</Link>
          </li>
        )
      })}
    </TagList>
  )
}
