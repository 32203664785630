import React from 'react'
import { Link, graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import EggheadEmbed from '../components/EggheadEmbed'
import Newsletter from '../components/Newsletter'
import styled from 'styled-components'
import Content from '../atoms/Content'
import PostTags from '../components/PostTags'

const Pagination = styled.div`
  margin-bottom: 60px;
  display: flex;
  width: 100%;

  @media (max-width: 760px) {
    flex-direction: column;
  }

  .link {
    display: flex;
    flex-direction: column;
    border: 2px solid ${props => props.theme.primaryColor};
    padding: 10px;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    font-weight: 500;
    border-radius: 4px;
    width: 100%;
    box-sizing: border-box;

    :hover {
      background-color: #3e86e4;
      color: #f5f7fa;
    }

    span {
      align-self: center;
    }
  }

  .prev {
    margin-right: 20px;

    a {
      align-self: center;
    }
  }

  .next {
    a {
      align-self: center;
    }
  }
`

class KbItemTemplate extends React.Component {
  render() {
    const { location } = this.props
    const post = this.props.data.mdx
    const siteTitle = this.props.data.site.siteMetadata.title
    const { previous, next } = this.props.pageContext
    const { embed, tags } = post.frontmatter
    const needsPagination = Boolean(previous) || Boolean(next)

    let newsletterType
    if (tags.includes('video')) {
      newsletterType = 'video'
    }

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={post.frontmatter.title} description={post.excerpt} />
        <h1>{post.frontmatter.title}</h1>
        {/* <p className={styles.date}>{post.frontmatter.date}</p> */}
        <PostTags tags={tags} collection="kb" />
        {embed && <EggheadEmbed src={embed} />}

        <Content>
          <MDXRenderer>{post.body}</MDXRenderer>
        </Content>

        {needsPagination && (
          <Pagination>
            {previous && (
              <Link
                to={previous.frontmatter.slug}
                rel="prev"
                className="link prev"
              >
                <span>← {previous.frontmatter.title}</span>
              </Link>
            )}

            {next && (
              <Link to={next.frontmatter.slug} rel="next" className="link next">
                <span>{next.frontmatter.title} →</span>
              </Link>
            )}
          </Pagination>
        )}
      </Layout>
    )
  }
}

export default KbItemTemplate

export const pageQuery = graphql`
  query KbItemBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    mdx(frontmatter: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      body
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        tags
      }
    }
  }
`
